import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de fins a 10 cm de diàmetre, convex al principi, després pla i deprimit al centre. És viscós en temps humit i de color ataronjat a tacat de verd o totalment verd i zonat. Les làmines de color taronja, rogenc i vinós, són nombroses, atapeïdes i amb moltes lamel·les un poc decurrents. El peu és cilíndric, un poc més clar que el capell i ple inicialment i després es buida al centre. Les espores són color ocraci clar, el·líptiques i amb berrugues, de 7,5-11 x 6-7,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      